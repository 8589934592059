<template>
  <vs-prompt
    title="Information"
    color="primary"
    @close="closeDetail"
    :buttons-hidden="true"
    :active.sync="showInfo"
  >
    <div class="flex gap-4 flex-col w-full h-full overflow-y-auto">
      <div class="flex flex-row gap-3 w-full">
        <vs-input
          class="w-1/2"
          label="Code Internal"
          name="Code Internal"
          v-model="documentCode"
          disabled
        />
        <vs-input
          class="w-1/2"
          label="Code External"
          name="Code External"
          v-model="documentExternalCode"
          disabled
        />
      </div>
      <div class="flex flex-row gap-3 w-full">
        <vs-input
          class="w-1/2"
          label="Item Value"
          name="Item Value"
          v-model="itemValue"
          disabled
        />
        <vs-input
          class="w-1/2"
          label="Tax Value"
          name="Tax Value"
          v-model="taxValue"
          disabled
        />
      </div>
      <div class="flex flex-row gap-3 w-full">
        <vs-input
          class="w-1/2"
          label="Total"
          name="Total"
          v-model="total"
          disabled
        />

        <vs-input
          class="w-1/2"
          label="Total Paid"
          name="Total Paid"
          v-model="totalPaid"
          disabled
        />
      </div>
      <div class="flex flex-row gap-3 w-full">
        <vs-input
          class="w-1/2"
          label="Total Unpaid"
          name="Total Unpaid"
          v-model="totalUnpaid"
          disabled
        />
        <vs-input
          class="w-1/2"
          label="No Shipment"
          name="No Shipment"
          v-model="purchaseInvoice.DeliveryOrderNumber"
          disabled
        />
      </div>
      <vs-divider ><b>Payment</b></vs-divider>
      <vs-table
        stripe
        border
        description
        class="w-full"
        :sst="true"
        :data="dataPayment"
        max-height="400px"
      >
        <template slot="thead">
          <vs-td style="text-align: center"><b>Payment Account</b></vs-td>
          <vs-td style="text-align: center"><b>Payment Voucher</b></vs-td>
          <vs-td style="text-align: right"><b>Paid</b></vs-td>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="data[indextr].class"
          >
            <vs-td style="text-align: center" :data="data[indextr].BankName">
              {{
                data[indextr].BankID == 0
                  ? data[indextr].Type == 2
                    ? "DN"
                    : "-"
                  : data[indextr].BankName + " " + data[indextr].AccountNumber
              }}
            </vs-td>
            <vs-td style="text-align: center">
              {{ data[indextr].ReferenceCode }}
            </vs-td>
            <vs-td style="text-align: right">
              {{
                data[indextr].PaidInvoiceValue.toString().replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )
              }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vs-prompt>
</template>

<script>
export default {
  props: {
    showInfo: {
      type: Boolean,
      default: false,
    },
    infoType: {
      type: String,
      default: "Purchase Invoice",
    },
    documentID: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    showInfo(val) {
      if (val) {
        this.getData();
      }
    },
  },
  data() {
    return {
      detailPrompt: false,
      documentCode: "",
      documentExternalCode: "",
      shipmentNumber: "",
      purchaseInvoice: {},
      itemValue: 0,
      taxValue: 0,
      total: 0,
      totalPaid: 0,
      totalUnpaid: 0,
      dataPayment: [],
    };
  },
  methods: {
    closeDetail() {
      this.$emit("closeDetail");
    },
    getData() {
      if (this.infoType == "Purchase Invoice") {
        this.getPurchaseInvoiceDetail(this.documentID);
      } else if (this.infoType == "CN Supplier") {
        this.getCreditNoteSupplierInfo(this.documentID);
      } else if (this.infoType == "Advance Invoice") {
        this.getAdvanceInvoiceInfo(this.documentID);
      }
    },
    getPurchaseInvoiceDetail(id) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/payment-proposal-line/" + id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            const purchaseInvoice = resp.data.purchase_invoice;
            this.documentCode = purchaseInvoice.CodeInternal;
            this.documentExternalCode = purchaseInvoice.Code;
            this.dataCharge = resp.data.data_charge;
            this.dataPayment = resp.data.detail_payment;
            this.dataPaymentProposal = resp.data.payment_proposal_proses;
            this.totalPaid = resp.data.paid_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.itemValue = resp.data.item_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            const whTax = resp.data.data_line.reduce((acc, item) => {
              if (this.purchaseInvoice.IsSkb === 1) {
                return acc;
              } else {
                return acc + item.wh_tax;
              }
            }, 0);
            this.total = resp.data.total_invoice_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.totalUnpaid = (resp.data.unpaid_value - whTax)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.taxValue = resp.data.tax_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.shipmentNumber = purchaseInvoice.DeliveryOrderNumber;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    getCreditNoteSupplierInfo(id) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/credit-note-supplier/line-for-proposal/" + id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            const cnSupplier = resp.data.credit_note_supplier;
            this.documentCode = cnSupplier.code;
            this.dataPayment = resp.data.payment;
            this.totalPaid = resp.data.paid
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.itemValue = resp.data.total_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total = resp.data.total_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.totalUnpaid = resp.data.unpaid_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.taxValue = (0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
    getAdvanceInvoiceInfo(id) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/advance-invoice/line-for-proposal/" + id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            const advanceInvoice = resp.data.advance_invoice;
            const line = resp.data.lines[0];
            this.documentCode = advanceInvoice.code;
            this.dataPayment = resp.data.payment;
            this.totalPaid = resp.data.paid
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.itemValue = line.dpp
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total = resp.data.total_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.totalUnpaid = resp.data.unpaid_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.taxValue = line.tax_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: err.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        });
    },
  },
};
</script>
