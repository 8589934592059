<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Detail Payment</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier</label>
        <multiselect
          class="selectExample"
          v-model="supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="true"
        >
          <!-- <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template> -->
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Proposal</label>
        <multiselect
          class="selectExample"
          v-model="paymentProposal"
          :options="optionPaymentProposal"
          :multiple="true"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Code"
          @search-change="getOptionPaymentProposal"
          :disabled="true"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }}</span>
              <br />
              <span class="option__desc">
                Due Date : {{ dateFormat(dt.option.DueDate) }}</span
              >
              <br />
              <span class="option__desc">
                Paid Proposal ({{ priceFormat(dt.option.PaidValue) }})</span
              >
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <template v-for="proposal in paymentProposal">
          <div v-bind:key="proposal.ID">
            <vs-divider style="width: 100%"
              ><b>{{ proposal.Code }}</b></vs-divider
            >
            Code : {{ getDocumentCode(proposal) }}
            <feather-icon
              v-on:click="showInfo(proposal)"
              icon="InfoIcon"
              svgClasses="h-4 w-4 clickable"
            /><br />
            Currency Code : {{ piCurrencyCode(proposal.ID) }}<br />
            Due Date : {{ dateFormat(proposal.DueDate) }}<br />
            Plan Payment Date : {{ dateFormat(proposal.PurposePaymentDate)
            }}<br />
            Paid Proposal : {{ priceFormat(proposal.PaidValue) }}<br />
            <vs-divider style="width: 100%"><b>Attachment</b></vs-divider>
            <div
              class="vx-row mb-3 mt-6 w-4/4"
              style="width: 100%; margin-left: 0%"
            >
              <table class="vs-table vs-table--tbody-table">
                <template v-for="tr in piAttachment(proposal.ID)">
                  <tr
                    class="tr-values vs-table--tr tr-table-state-null selected"
                    v-bind:key="tr.name"
                  >
                    <td class="td vs-table--td">{{ tr.name }}</td>
                    <td class="td vs-table--td">
                      <vx-tooltip
                        text="Show"
                        v-if="
                          tr.path != '' &&
                          canView
                        "
                      >
                        <vs-button
                          type="line"
                          icon-pack="feather"
                          @click.stop="downloadFileAwsS3(tr.path)"
                          icon="icon-eye"
                        />
                      </vx-tooltip>
                    </td>
                    <!-- <td class="td vs-table--td">
                      <template>
                        <vx-tooltip text="Delete">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            @click.stop="handleDeleteAttachment(i, tr.id)"
                            icon="icon-trash"
                          />
                        </vx-tooltip>
                      </template>
                    </td> -->
                  </tr>
                </template>
              </table>
            </div>
            <vs-divider style="width: 100%"><b>Note</b></vs-divider>
            <template v-for="note in piNotes(proposal.ID)">
              <b v-bind:key="note"
                >{{ note }}
                <br />
              </b>
            </template>
            <vs-divider style="width: 100%"><b>Comment</b></vs-divider>
            {{ piComment(proposal.ID) }}

            <vs-divider style="width: 100%"></vs-divider>
            <vs-divider style="width: 100%"></vs-divider>
          </div>
        </template>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Operating Unit Bank</label>
        <multiselect
          class="selectExample"
          v-model="operatingUnitBank"
          :options="optionBankOperatingUnit"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          :custom-label="operatingUnitBankQuery"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                bank.filter((v) => {
                  return v.ID == dt.option.BankID;
                })[0].Name +
                " " +
                dt.option.AccountNumber +
                " (" +
                dt.option.AccountName +
                ")"
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                bank.filter((v) => {
                  return v.ID == dt.option.BankID;
                })[0].Name +
                " " +
                dt.option.AccountNumber +
                " (" +
                dt.option.AccountName +
                ")"
              }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier Bank</label>
        <multiselect
          class="selectExample"
          v-model="supplierBank"
          :options="optionBankSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="BankName"
          :disabled="true"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title"
                >{{ dt.option.BankName }} {{ dt.option.AccountNumber }} ({{
                  dt.option.AccountName
                }})
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName }} {{ dt.option.AccountNumber }} ({{
                  dt.option.AccountName
                }})
              </span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-full mb-5">
        <vs-input
          class="w-full"
          label="Reference Code"
          name="Reference Code"
          v-model="reference_code"
        />
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Transaction Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="date_clearing"
          placeholder="Date Clearing"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>Payment Notes</b></vs-divider
      >
      <template v-for="note in notes">
        <b v-bind:key="note"
          >{{ note }}
          <br />
        </b>
      </template>
      <vs-textarea v-model="new_notes" />
      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>List Attachment</b></vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png .pdf"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        ><b>List Attachment</b></vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr, i) in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.name"
            >
              <td class="td vs-table--td">{{ tr.name }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.path != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.path)"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(i, tr.id)"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <div class="vx-col w-full" v-if="selected.CountPrint > 0">
        <br />
        <vs-button v-if="canEdit" class="mb-2" v-on:click="handleOUBank"
          >Save OU Bank</vs-button
        >
        &nbsp;
        <vs-button v-if="canEdit" class="mb-2" v-on:click="handleCheck"
          >Confirm Payment</vs-button
        >

        <vs-button
          v-if="canCancel"
          color="red"
          class="mb-2 ml-2"
          v-on:click="handleFailed"
          >Failed Payment</vs-button
        >
      </div>
      <div v-else>
        <vs-button v-if="canEdit" class="mb-2" v-on:click="handleOUBank"
          >Save OU Bank</vs-button
        >

        <vs-button
          v-if="canCancel"
          color="red"
          class="mb-2 ml-2"
          v-on:click="handleFailed"
          >Cancel Payment</vs-button
        >
      </div>
    </div>
    <vs-prompt
      title="Confirmation"
      color="primary"
      @accept="handleSubmit"
      @cancel="() => (this.confirmation = false)"
      @close="() => (this.confirmation = false)"
      :accept-text="'Yes'"
      :cancel-text="'No'"
      :buttons-hidden="false"
      :active.sync="confirmation"
    >
      <div class="con-exemple-prompt prompt-xs">
        Printed Document Exchange Rate : {{ priceFormat(oldExchangeRate) }}
        <br />
        <br />
        Current Exchange Rate : {{ priceFormat(newExchangeRate) }}
      </div>
    </vs-prompt>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  props: {
    selected: Object,
  },
  methods: {
    initialState() {
      return {
        file: [],
        fileAttachment: [],
        search: "",
        supplier: null,
        operatingUnitBank: null,
        supplierBank: null,
        bank: [],
        paymentProposal: [],
        optionSupplier: [],
        optionPaymentProposal: [],
        optionBankOperatingUnit: [],
        optionBankSupplier: [],
        notes: [],
        purchaseInvoice: [],
        reference_code: "",
        date_clearing: "",
        new_notes: "",
        confirmation: false,
        oldExchangeRate: 0,
        newExchangeRate: 0,
        newExchangeRateID: 0,
        newExchange: [],
        totalNew: 0,
        creditNoteSuppliers: [],
        advanceInvoices: [],
        documentType: "Purchase Invoice",
      };
    },
    piNotes(id) {
      let notes = [];
      if (this.documentType === "Purchase Invoice") {
        notes =
          this.purchaseInvoice[0].purchaseInvoice.Notes.toString().split(";");
      } else if (this.documentType === "CN Supplier") {
        notes = this.creditNoteSuppliers[0].creditNoteSupplier.note
          .toString()
          .split(";");
      } else if (this.documentType === "Advance Invoice") {
        notes = this.advanceInvoices[0].advanceInvoice.notes
          .toString()
          .split(";");
      }
      return notes;
    },
    piComment(id) {
      let comment = "";
      if (this.documentType === "Purchase Invoice") {
        comment = this.purchaseInvoice[0].purchaseInvoice.CommentInvoice;
      }

      return comment;
    },
    showInvoice(id) {
      this.$emit("detailPurchaseInvoice", id);
    },
    piCurrencyCode(id) {
      let currencyCode = "IDR";
      if (this.documentType === "Purchase Invoice") {
        this.purchaseInvoice[0].purchaseInvoice.CurrencyCode;
      }

      return currencyCode;
    },
    piAttachment(id) {
      let attachment = [];
      if (this.documentType === "Purchase Invoice") {
        attachment = this.purchaseInvoice[0].purchaseInvoice.attachment;
      } else if (this.documentType === "CN Supplier") {
        attachment = this.creditNoteSuppliers[0].attachment;
      } else if (this.documentType === "Advance Invoice") {
        attachment = this.advanceInvoices[0].attachment;
      }
      return attachment;
    },
    getDocumentCode(proposal) {
      if (proposal.PurchaseInvoiceID) {
        return proposal.PurchaseInvoiceCode;
      } else if (proposal.CnSupplierID) {
        return proposal.CnSupplierCode;
      } else if (proposal.AdvanceInvoiceID) {
        return proposal.AdvanceInvoiceCode;
      }
    },
    showInfo(proposal) {
      let documentID = 0;
      if (proposal.PurchaseInvoiceID) {
        documentID = proposal.PurchaseInvoiceID;
      } else if (proposal.CnSupplierID) {
        documentID = proposal.CnSupplierID;
      } else if (proposal.AdvanceInvoiceID) {
        documentID = proposal.AdvanceInvoiceID;
      }

      this.$emit("showInfo", documentID, this.documentType);
    },
    handleOUBank() {
      if (this.operatingUnitBank == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      const params = {
        id: this.selected.ID,
        bank_id: this.operatingUnitBank.BankID,
        bank_name: this.bank.filter((v) => {
          return this.operatingUnitBank.BankID == v.ID;
        })[0].Name,
        account_number: this.operatingUnitBank.AccountNumber,
        account_name: this.operatingUnitBank.AccountName,
        account_branch: this.operatingUnitBank.BranchName,
        type_update: "OuBank",
      };
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/purchase-payment-request/update", params)
        .then((result) => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },

    handleCheck() {
      if (this.paymentProposal.length < 1) {
        this.$vs.notify({
          title: "Error",
          text: "please select payment proposal first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      if (this.operatingUnitBank == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      if (this.reference_code == "" || this.reference_code == null) {
        this.$vs.notify({
          title: "Error",
          text: "reference code can not be empty",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      const params = {
        purchase_payment_request_id: this.selected.ID,
        old_exchange_rate_id: this.selected.CurrencyExchangeRateID,
        clearing_date: moment(this.date_clearing).format("YYYY-MM-DD"),
      };

      if (
        this.piCurrencyCode(this.paymentProposal[0].ID) == "IDR" &&
        this.selected.CurrencyCode == "IDR"
      ) {
        this.handleSubmit();
        // console.log("ini jalan piCurrencyCode");
      } else {
        console.log(this.selected, "selected");
        this.$vs.loading();
        //console.log(params)
        this.$http
          .post("/api/v1/purchase-payment-request/check-exchange-rate", params)
          .then((result) => {
            //console.log(result)
            this.$vs.loading.close();
            if (result.code == 200) {
              console.log(result.data.new);
              this.newExchange = result.data.new;
              if (this.newExchange == null) {
                console.log("ini jalan");
                this.newExchangeRateID = 0;
                this.totalNew = this.selected.Total * 1;
                this.handleSubmit();
                // this.$vs.notify({
                //   title: "Error",
                //   text: "Exchange not found",
                //   color: "danger",
                //   position: "top-right",
                //   iconPack: "feather",
                //   icon: "icon-check",
                // });
              } else {
                // this.oldExchangeRate = this.selected.Total * this.selected.CurrencyExchangeRate;
                this.newExchangeRate =
                  this.selected.Total * result.data.new.ExchangeRate;
                this.newExchangeRateID = result.data.new.ID;
                this.totalNew =
                  this.selected.Total * result.data.new.ExchangeRate;
                // this.confirmation = true;
                this.handleSubmit();
              }
              // this.handleClose()
              // this.$vs.notify({
              //   title: "Success",
              //   text: "",
              //   color: "success",
              //   position: "top-right",
              //   iconPack: "feather",
              //   icon: "icon-check",
              // });
            } else {
              this.$vs.notify({
                title: "Error",
                text: result.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
          })
          .catch((e) => {});
      }
    },

    handleSubmit() {
      if (this.paymentProposal.length < 1) {
        this.$vs.notify({
          title: "Error",
          text: "please select payment proposal first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      if (this.operatingUnitBank == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      if (this.reference_code == "" || this.reference_code == null) {
        this.$vs.notify({
          title: "Error",
          text: "reference code can not be empty",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      console.log("ini jalan4");

      let line = [];
      let total = parseFloat(0);
      this.paymentProposal.map((v) => {
        total += parseFloat(v.PaidValue);
        line.push({
          proposal_id: v.ID,
          code: v.Code,
        });
      });

      const params = {
        id: this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 3,
        reference_code: this.reference_code,
        notes: this.new_notes,
        clearing_date: moment(this.date_clearing).format("YYYY-MM-DD"),
        currency_exchange_rate_id: this.newExchangeRateID,
        currency_exchange_rate: this.newExchangeRate,
        total_new: this.totalNew,
      };
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/purchase-payment-request/confirm", params)
        .then((result) => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
    handleSubmit1() {
      if (this.paymentProposal.length < 1) {
        this.$vs.notify({
          title: "Error",
          text: "please select payment proposal first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      if (this.operatingUnitBank == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }

      // //console.log(this.operatingUnitBank,this.supplierBank)

      let line = [];
      let total = parseFloat(0);
      this.paymentProposal.map((v) => {
        total += parseFloat(v.PaidValue);
        line.push({
          proposal_id: v.ID,
          code: v.Code,
        });
      });
      const params = {
        id: this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 3,
        reference_code: this.reference_code,
      };
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/purchase-payment-request/confirm", params)
        .then((result) => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
    handleFailed() {
      // //console.log(this.operatingUnitBank,this.supplierBank)

      let line = [];
      let total = parseFloat(0);
      this.paymentProposal.map((v) => {
        total += parseFloat(v.PaidValue);
        line.push({
          proposal_id: v.ID,
          code: v.Code,
        });
      });
      const params = {
        id: this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 4,
        reference_code: this.reference_code,
      };
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/purchase-payment-request/change-status", params)
        .then((result) => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
    handleCancel() {
      // //console.log(this.operatingUnitBank,this.supplierBank)

      let line = [];
      let total = parseFloat(0);
      this.paymentProposal.map((v) => {
        total += parseFloat(v.PaidValue);
        line.push({
          proposal_id: v.ID,
          code: v.Code,
        });
      });
      const params = {
        id: this.selected.ID,
        purchase_payment_request_id: this.selected.ID,
        status_purchase_request: 6,
        reference_code: this.reference_code,
      };
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/purchase-payment-request/change-status", params)
        .then((result) => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    handleShowAttachment(selected) {
      console.log(selected);
      if (selected.path != "" && selected.path !== undefined) {
        window.open(selected.path);
      } else if (selected.PathFile != "" && selected.PathFile !== undefined) {
        window.open(selected.PathFile);
      }
    },
    handleDeleteAttachment(index, id) {
      this.fileAttachment = this.fileAttachment.filter((item, i) => i != index);
      this.deleteFile(id);
      console.log(this.fileAttachment);
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.selected.ID);
      form.append("file", this.file[array]);
      return form;
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/purchase-payment-request/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    uploadData(array) {
      this.$vs.loading();
      this.$http
        .post(
          "/api/v1/purchase-payment-request/upload-file",
          this.paramUpload(array),
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((resp) => {
          this.fileAttachment.push({
            id: resp.data.attachment.ID,
            name: resp.data.attachment.NameFile,
            path: resp.data.attachment.PathFile,
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          this.uploadData(i);
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getOptionPaymentProposal(query) {
      //console.log(this.supplier)
      this.$http
        .get("/api/v1/purchase-payment-proposal", {
          params: {
            status: 1,
            request_code: this.selected.Code,
            search: query,
            length: 100,
            still_empty: 1,
            // page: params.page,
            // order: params.sort,
            // sort: params.order,
            supplier_code: this.supplier.Code,
          },
        })
        .then((r) => {
          this.optionPaymentProposal = r.data.purchasePaymentProposal;
          //console.log(r)
        })
        .catch((e) => {
          //console.log(e)
        });
    },
    getOptionBankOperationUnit() {
      this.$http
        .get("/api/v1/purchase-payment-proposal/bank-operating-unit")
        .then((r) => {
          this.optionBankOperatingUnit = r.data.dataOperatingUnitBank;
          this.bank = r.data.dataBank;
          if (this.selected.ID) {
            r.data.dataOperatingUnitBank.map((v) => {
              console.log(
                "this.selected.ToBankName: ",
                v.AccountNumber == this.selected.AccountNumber
              );
              if (v.AccountNumber == this.selected.AccountNumber) {
                this.operatingUnitBank = v;
              }
            });
          }
        })
        .catch((e) => {
          //console.log(e)
        });
    },
    getOptionSupplierBank() {
      this.$http
        .get("/api/v1/pi/supplier-account/" + this.supplier.ID)
        .then((r) => {
          this.optionBankSupplier = r.data.account;
          //console.log(this.selected)
          if (this.selected.ID) {
            r.data.account.map((v) => {
              if (v.AccountNumber == this.selected.ToAccountNumber) {
                this.supplierBank = v;
              }
            });
          }
          //console.log(r)
        })
        .catch((e) => {
          //console.log(e)
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/purchase-payment-request/line/" + this.selected.ID)
        .then((result) => {
          this.$vs.loading.close();
          this.paymentProposal = [];
          // result.data.purchasePaymentProposal.map((v) => {
          //   this.paymentProposal.push(v);
          // });
          this.paymentProposal = result.data.purchasePaymentProposal;
          this.documentType = result.data.type;
          // this.purchaseInvoice = [];
          // result.data.purchaseInvoice.map((v) => {
          //   this.purchaseInvoice.push(v);
          // });
          this.fileAttachment = [];
          result.data.attechment.map((v) => {
            this.fileAttachment.push({
              id: v.ID,
              name: v.NameFile,
              path: v.PathFile,
            });
          });
          if (this.documentType === "Purchase Invoice") {
            this.purchaseInvoice = result.data.purchaseInvoice;
          } else if (this.documentType === "CN Supplier") {
            this.creditNoteSuppliers = result.data.purchaseInvoice;
          } else if (this.documentType === "Advance Invoice") {
            this.advanceInvoices = result.data.purchaseInvoice;
          }
          this.notes = this.selected.Notes.toString().split(";");
        })
        .catch((e) => {
          this.$vs.loading.close();
        });
    },
    getOptionSupplier(query) {
      if (query.length <= 2) {
        return;
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
            search: query,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
            if (this.selected.ID) {
              resp.data.records.map((v) => {
                if (v.Code == this.selected.SupplierCode) {
                  this.supplier = v;
                }
              });
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },

    closeDetail() {
      this.activePrompt2 = false;
    },

    operatingUnitBankQuery({ BankID, AccountNumber, AccountName }) {
      return (
        this.bank.filter((v) => {
          return v.ID == BankID;
        })[0].Name +
        " " +
        AccountNumber +
        " (" +
        AccountName +
        ")"
      );
    },
  },
  mounted() {
    if (this.selected.SupplierCode != "ONETIME") {
      this.getOptionSupplier(this.selected.SupplierName);
    } else {
      console.log(this.selected, "Supplier this selected");
      const supplier = [
        {
          Name: this.selected.SupplierName,
          Code: this.selected.SupplierCode,
        },
      ];
      this.supplier = supplier;

      const bankSupplier = [
        {
          BankName: this.selected.ToBankName,
          AccountNumber: this.selected.ToAccountNumber,
          AccountName: this.selected.ToAccountName,
        },
      ];
      this.supplierBank = bankSupplier;
    }
    this.getOptionBankOperationUnit();
    this.getData();
    this.getOptionSupplier(this.selected.SupplierName);
    this.new_notes =
      this.selected.SupplierCode + " " + this.selected.SupplierName;
    this.date_clearing = new Date(moment());
    // this.getOptionPaymentTerm();
    // this.getOptionSupplier();
    // this.getOptionWarehouse();
  },
  computed: {
    canEdit() {
      return this.$store.getters["user/hasPermissions"]("edit");
    },
    canCancel() {
      return this.$store.getters["user/hasPermissions"]("cancel");
    },
    canView() {
      return this.$store.getters["user/hasPermissions"]("view");
    },
  },
  watch: {
    "supplier.ID"() {
      this.getOptionSupplierBank();
    },
    "selected.ID"() {
      //console.log("selected change")
      this.getData();
      this.getOptionSupplier(this.selected.SupplierName);
      this.getOptionSupplierBank();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
